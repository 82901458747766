import { Theme, createStyles } from '@material-ui/core/styles';


export const styles = (theme: Theme) => createStyles({
    root: {
        margin: 0,
        fontFamily: 'MuseoSans, sans-serif',
        width: '65%',
        marginBottom: '300px',
        marginLeft: '30%',
        marginTop: '237px',
        [theme.breakpoints.down(1000)]: {
            width: '70%',
            marginLeft: '22%',
        },
        [theme.breakpoints.down(701)]: {
            width: '71%',
            marginLeft: '21%',
        },
        [theme.breakpoints.down(650)]: {
            marginBottom: '100px',
            marginTop: '150px',
        },
        [theme.breakpoints.down(501)]: {
            marginBottom: '85px',
            marginTop: '125px',
        },
    },
    container: {
        display: 'grid',
        gridTemplateColumns: '40% 60%',
        gridTemplateRows: 'auto auto auto auto auto',
        marginTop: '85px',
        marginBottom: '50px',
        [theme.breakpoints.down(1050)]: { display: 'block' },
        [theme.breakpoints.down(501)]: { marginTop: '40px' },
    },
    titleContainer: {
        marginBottom: '133px',
        '& #bot': { marginLeft: '59.5px', },
        [theme.breakpoints.down(1000)]: {
            marginBottom: '70px',
            '& #bot': { marginLeft: 0, },
        },
        [theme.breakpoints.down(701)]: { marginBottom: '60px' },
        [theme.breakpoints.down(500)]: { marginBottom: '40px' },
    },
    title: {
        fontSize: '61px',
        color: '#3D4975',
        letterSpacing: '5px',
        fontWeight: 300,
        marginBottom: 0,
        [theme.breakpoints.down(1000)]: {
            fontSize: '55.24px',
            letterSpacing: '6.34px',
        },
        [theme.breakpoints.down(701)]: {
            fontSize: '52.24px',
            letterSpacing: '6px',
        },
        [theme.breakpoints.down(650)]: {
            fontSize: '45px',
            letterSpacing: '5.5px',
        },
        [theme.breakpoints.down(501)]: {
            fontSize: '35px',
            letterSpacing: '1.5px',
        },
    },
    firstText: {
        fontSize: '24px',
        color: theme.palette.primary.main,
        letterSpacing: '1px',
        lineHeight: '38px',
        fontWeight: 500,
        margin: '0 0 0 0',
        [theme.breakpoints.down(769)]: {
            fontSize: '21px',
            letterSpacing: '0.75px',
            lineHeight: '30px',
        },
        [theme.breakpoints.down(701)]: {
            fontSize: '19px',
            letterSpacing: '0.34px',
            lineHeight: '26.35px',
        },
        [theme.breakpoints.down(650)]: {
            fontSize: '16px',
            letterSpacing: '0.5px',
            lineHeight: '22px',
        },
        [theme.breakpoints.down(501)]: {
            fontSize: '10.86px',
            letterSpacing: '0.25px',
            lineHeight: '20px',
        },
    },
    subSectionIndex: {
        fontWeight: 500,
        fontSize: '32px',
        color: '#B2B6C8',
        marginRight: '35px !important',
        letterSpacing: '1.13px',
        lineHeight: '45px',
        [theme.breakpoints.down(1000)]: {
            fontSize: '30px',
            letterSpacing: '2.11px',
            lineHeight: '52px',
        },
        [theme.breakpoints.down(701)]: {
            fontSize: '29px',
            letterSpacing: '2px',
            lineHeight: '42px',
        },
        [theme.breakpoints.down(650)]: {
            fontSize: '26px',
            letterSpacing: '1.8px',
            lineHeight: '40px',
        },
        [theme.breakpoints.down(501)]: {
            fontSize: '20px',
            letterSpacing: '0.3px',
            lineHeight: '23px',
            marginBottom: '20px',
        },
    },
    subSectionText: {
        fontWeight: 500,
        fontSize: '18.3px',
        color: theme.palette.primary.main,
        letterSpacing: '0.5px',
        lineHeight: '26.35px',
        paddingTop: '10px',
        [theme.breakpoints.down(769)]: {
            fontSize: '21px',
            letterSpacing: '0.75px',
            lineHeight: '30px',
        },
        [theme.breakpoints.down(701)]: {
            fontSize: '19px',
            letterSpacing: '0.34px',
            lineHeight: '26.35px',
        },
        [theme.breakpoints.down(650)]: {
            fontSize: '16px',
            letterSpacing: '0.5px',
            lineHeight: '22px',
        },
        [theme.breakpoints.down(501)]: {
            fontSize: '10.86px',
            letterSpacing: '0.25px',
            lineHeight: '20px',
        },
    },
    straightLine: {
        borderBottom: '1px solid #3D4274',
        marginBottom: '15px',
        paddingBottom: '15px',
    },
    topMargin: {
      marginTop: '50px',
    },
});
