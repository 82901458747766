// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

// local imports
import { styles } from './PrivacyPolicy.style';

function PrivacyPolicy(props: WithStyles<typeof styles>) {
    const { classes } = props;

    return(
        <Grid container className={classes.root}>
            <Grid item xs={12}>

                <Box className={classes.titleContainer}>
                    <p className={classes.title}>Aviso de</p>
                    <p id='bot' className={classes.title}>privacidad</p>
                </Box>

                <Box className={classes.firstText}>
                  <p>Tarjetas Cuenca S.A. de C.V., conocida comercialmente y en
                   lo sucesivo como “Tarjetas Cuenca”, con domicilio para oír y
                   recibir notificaciones en el ubicado en Varsovia 36, piso 9,
                   oficina 912, Colonia Juárez, Delegación Cuauhtémoc, Código
                   Postal 06600, Ciudad de México, es el responsable del uso y
                   protección de sus datos personales, y al respecto le informamos
                   lo siguiente:</p>
                </Box>
                <Box className={classes.container}>
                  <Box />

                  <Box className={classes.subSectionText}>
                    <p>
                      Este Aviso de Privacidad está elaborado en cumplimiento con
                      las disposiciones legales en materia de protección de datos
                      personales de la Ley Federal de Protección de Datos Personales
                      en Posesión de Particulares (LFPDPPP), su Reglamento y los
                      Lineamientos del Aviso de Privacidad emitidos por el Instituto
                      Nacional de Transparencia, Acceso a la Información y Protección
                      de Datos Personales (INAI).
                    </p>
                    <p>
                      Para Tarjetas Cuenca es muy importante la seguridad de sus
                      datos personales, por lo que contamos con mecanismos tecnológicos,
                      físicos, administrativos y legales para proteger y salvaguardar
                      sus datos personales, así como para evitar el daño, pérdida,
                      destrucción, robo, extravío, alteración, y el tratamiento no
                      autorizado.
                    </p>
                    <p>
                      Tarjetas Cuenca pone a su disposición este Aviso de Privacidad,
                      para informarle la forma en la que recabamos, transferimos y
                      utilizamos su información confidencial. Asimismo, estará disponible
                      para su consulta en nuestro Sitio Web y Aplicación.
                    </p>
                    <p>
                      Las finalidades del tratamiento de los datos personales son
                      las siguientes:
                    </p>
                    <p className={classes.straightLine}>
                      Proporcionar, mantener y mejorar nuestros Servicios.
                    </p>
                    <p className={classes.straightLine}>
                      Corroborar su identidad y veracidad de la información proporcionada.
                    </p>
                    <p className={classes.straightLine}>
                      Cumplir con disposiciones de conocimiento del cliente y
                      prevención de lavado de dinero y financiamiento al terrorismo,
                      conforme a la normatividad aplicable.
                    </p>
                    <p className={classes.straightLine}>
                      Para llevar a cabo análisis de riesgo y viabilidad en el
                      otorgamiento de crédito.
                    </p>
                    <p className={classes.straightLine}>
                      Compartir con el cliente información relacionada, respecto
                      de recordatorios, notificaciones, confirmaciones, cancelaciones,
                      resúmenes de transacciones, avisos, actualizaciones, mensajes.
                    </p>
                    <p className={classes.straightLine}>
                      Atender a sus solicitudes, comentarios, preguntas, peticiones
                      y cualquier tema relacionado con proporcionar Servicio al cliente.
                    </p>
                    <p className={classes.straightLine}>
                      Analizar las tendencias, usos y actividades en relación
                      con nuestros Servicios, así como supervisarlas
                    </p>
                    <p className={classes.straightLine}>
                      Prevenir y detectar actividades ilegales, transacciones
                      fraudulentas, en aras de garantizar la continuidad y seguridad
                      en el sistema de pagos.
                    </p>
                    <p className={classes.straightLine}>
                      Mantener información con el cliente acerca de nuestros Servicios,
                      productos, noticias, eventos e información que consideremos pueda
                      ser de interés para el cliente.
                    </p>
                    <p className={classes.straightLine}>
                      Proporcionar contenido personalizado sobre nuestros Servicios de
                      acuerdo con las preferencias e intereses de los clientes.
                    </p>
                    <p className={classes.straightLine}>
                      Ofrecer un mejor Servicio a través de la vinculación con información
                      que obtenemos de terceros para ayudar a comprender mejor sus necesidades.
                    </p>
                    <p className={classes.topMargin}>
                      De manera adicional, utilizaremos su información personal para
                      las siguientes finalidades que no son necesarias para el Servicio,
                      pero que nos permiten y facilitan brindarle una mejor atención:
                    </p>
                    <p className={classes.straightLine}>
                      Crear bases de datos para lograr estudios de mercado.
                    </p>
                    <p className={classes.straightLine}>
                      Enviar notificaciones, avisos, mensajes promocionales o comunicaciones
                      con fines de mercadotecnia o publicidad o telemarketing sobre productos
                      y servicios nuevos o existentes de socios comerciales; realizar
                      encuestas; estadísticas; estudios de mercado, registros sobre hábitos
                      de consumo a través de herramientas de captura automática de datos,
                      intereses y comportamiento.
                    </p>
                    <p className={classes.topMargin}>
                      En caso de que el cliente no desee que sus datos personales sean
                      tratados para estos fines adicionales, desde este momento nos puede
                      comunicar lo anterior, mediante el envío de un correo electrónico a
                      la siguiente dirección <i>soporte@tarjetascuenca.com</i> señalando la
                      intención de excluir el tratamiento de sus datos personales para
                      los fines adicionales. Desde la recepción de su correo y la
                      confirmación de recibido del mismo, Tarjetas Cuenca se obliga a
                      no tratar los datos personales para las finalidades adicionales
                      mencionadas sin que esto se entienda que el Aviso de Privacidad
                      deje de estar en vigor, el correo electrónico y su contenido sobre
                      la exclusión de las finalidades adicionales solo constituye una
                      reserva de las finalidades adicionales que se establecen. La negativa
                      para el uso de sus datos personales para estas finalidades
                      adicionales no podrá ser un motivo para que le neguemos los Servicios
                      que solicita o contrata con Tarjetas Cuenca.
                    </p>
                    <p>
                      Los datos personales que recaudaremos del cliente que son necesarios
                      para poder otorgar los Servicios de Tarjetas Cuenca, son los siguientes:
                    </p>
                    <p>
                      Apellido paterno, apellido materno y nombre o nombres; género;
                      fecha de nacimiento; entidad federativa de nacimiento; país de
                      nacimiento; nacionalidad; clave de elector, en su caso; domicilio
                      particular del país de residencia y en caso de tener domicilio
                      en territorio nacional, también se recabará ese, compuesto por
                      nombre de la calle, avenida o vía de que se trate, debidamente
                      especificada; número exterior y, en su caso, interior; colonia
                      o urbanización; alcaldía, delegación, municipio o demarcación
                      política similar que corresponda, en su caso; ciudad o población,
                      entidad federativa, estado, provincia, departamento o demarcación
                      política similar que corresponda, en su caso; código postal y
                      país; ocupación, profesión, actividad o giro del negocio al que
                      se dedica el cliente; Clave Única de Registro de Población (CURP);
                      número de Registro Federal del Contribuyente (RFC), firma autógrafa
                      digitalizada; número telefónico; dirección de correo electrónico;
                      datos biométricos; datos de geolocalización; número de cuenta y
                      Clave Bancaria Estandarizada (CLABE) en la Entidad Financiera o
                      Entidad Financiera Extranjera autorizadas para recibir depósitos,
                      y en algunos casos que se considere pertinente a criterio de Tarjetas
                      Cuenca se solicitarán documentos digitalizados que comprueben la
                      veracidad de los datos proporcionados a Tarjetas Cuenca.
                    </p>
                    <p>
                      Asimismo, los datos personales que se consideren dentro de las
                      siguientes categorías, sin incluir datos personales sensibles:
                    </p>
                    <p className={classes.straightLine}>
                      Datos de identificación
                    </p>
                    <p className={classes.straightLine}>
                      Datos de contacto
                    </p>
                    <p className={classes.straightLine}>
                      Datos laborales
                    </p>
                    <p className={classes.straightLine}>
                      Datos académicos
                    </p>
                    <p className={classes.straightLine}>
                      Datos financieros
                    </p>
                    <p className={classes.straightLine}>
                      Datos patrimoniales
                    </p>
                  </Box>
                </Box>
                <Box className={classes.container}>
                  <Box>
                    <p className={classes.subSectionIndex}>
                      Datos personales sensibles
                    </p>
                  </Box>

                  <Box className={classes.subSectionText}>
                    <p>
                      Tarjetas Cuenca no solicitará datos personales sensibles,
                      los cuales de conformidad con el artículo 3 fracción VI de
                      la LFPDPPP son: aquellos datos personales que afecten a la
                      esfera más íntima de su titular, o cuya utilización indebida
                      pueda dar origen a discriminación o conlleve un riesgo grave
                      para éste. En particular, se consideran sensibles aquellos que
                      puedan revelar aspectos como origen racial o étnico, estado de
                      salud presente y futuro, información genética, creencias
                      religiosas filosóficas y morales, afiliación sindical,
                      opiniones políticas, preferencia sexual.
                    </p>
                  </Box>
                </Box>
                <Box className={classes.container}>
                  <Box>
                    <p className={classes.subSectionIndex}>
                      Transferencia de datos personales
                    </p>
                  </Box>

                  <Box className={classes.subSectionText}>
                    <p>
                      Le informamos que sus datos personales son compartidos dentro
                      y fuera del país con las siguientes empresas:
                    </p>
                    <p className={classes.straightLine}>
                      Cuenca Incorporated, con domicilio en los Estados Unidos de América.
                    </p>
                    <p className={classes.straightLine}>
                      Tamizi, S.A. de C.V, con domicilio en Varsovia 36, Piso 9,
                      Oficina 910, Colonia Juárez, Alcaldía Cuauhtémoc, C.P 06600,
                      en esta Ciudad de México.
                    </p>
                    <p className={classes.straightLine}>
                      Trans Union de México, S.A. Sociedad de Información Crediticia
                      o con cualquier otra Sociedad de información crediticia.
                    </p>
                    <p className={classes.topMargin}>
                      La transferencia antes mencionada, se realizará con la única
                      finalidad de prestarle al Cliente los servicios de Tarjetas Cuenca,
                      así como cualquier otro servicio relacionado.
                    </p>
                    <p>
                      La prestación de los Servicios de Tarjetas Cuenca, su operación,
                      procesos y políticas internas son las mismas al ser parte del
                      mismo responsable, por lo que se entiende que no se requerirá
                      consentimiento expreso para aceptar este intercambio de información
                      en términos de lo establecido en el artículo 37 fracción III de la
                      LFPDPPP. La negativa para esta transferencia de datos personales
                      constituye un motivo para que le neguemos los Servicios que solicita
                      o contrata con Tarjetas Cuenca.
                    </p>
                    <p>
                      Tarjetas Cuenca no venderá, cederá o transferirá sus datos personales
                      a terceros ajenos a la empresa, sus filiales, subsidiarias y partes
                      relacionadas, sin su consentimiento previo. Tarjetas Cuenca podrá
                      transferir aquellos datos que sean necesarios para el cumplimiento de
                      sus obligaciones con el cliente motivo de la prestación de los
                      Servicios, así como con las autoridades que estén debidamente
                      facultadas a solicitar información de nuestros clientes.
                    </p>
                  </Box>
                </Box>
                <Box className={classes.container}>
                  <Box>
                      <p className={classes.subSectionIndex}>
                          Derechos ARCO
                      </p>
                  </Box>

                  <Box className={classes.subSectionText}>
                    <p>
                      El cliente tiene derecho a conocer los datos personales que
                      almacenamos de él, para qué los utilizamos y las condiciones
                      del uso que les damos (Acceso). Asimismo, es su derecho
                      solicitar la corrección de su información personal en caso
                      de que esté desactualizada, sea inexacta o incompleta
                      (Rectificación); que la eliminemos de nuestros registros
                      o bases de datos cuando considere que la misma no está
                      siendo utilizada conforme a los principios, deberes y
                      obligaciones previstas en la normativa (Cancelación);
                      así como oponerse al uso de sus datos personales para
                      fines específicos (Oposición). Estos derechos se conocen
                      como derechos ARCO.
                    </p>
                    <p>
                      Para el ejercicio de cualquiera de los derechos ARCO,
                      el cliente deberá presentar la solicitud respectiva, en el
                      siguiente correo electrónico <i>soporte@tarjetascuenca.com</i>,
                      dicha solicitud deberá contener los requisitos siguientes:
                      (i) nombre del titular de la información, su domicilio y
                      correo electrónico u otro medio para comunicar la respuesta;
                      (ii) documentos que acrediten su identidad o bien la representación
                      legal correspondiente; (iii) descripción clara y precisa de
                      los datos personales respecto de los cuales se ejerza el
                      derecho correspondiente; y (iv) cualquier otro elemento que
                      facilite la localización de los datos personales. El cliente
                      tiene la obligación de observar los requisitos pertinentes
                      para el ejercicio de sus Derechos ARCO. Asimismo, el derecho
                      de Rectificación lo podrá ejercer mediante mensaje a través
                      de una aplicación ampliamente conocida y utilizada en todo
                      çel mundo de mensajería para teléfonos inteligentes que envíe
                      y reciba mensajes mediante internet o desde nuestro sitio web
                      <i>www.tarjetascuenca.com</i>. Recibida cualquier solicitud, tendremos
                      20 días hábiles para responder por el mismo medio.
                    </p>
                  </Box>
                </Box>
                <Box className={classes.container}>
                  <Box>
                    <p className={classes.subSectionIndex}>
                      Revocación del consentimiento del cliente para el uso de
                      sus datos personales
                    </p>
                  </Box>

                  <Box className={classes.subSectionText}>
                    <p>
                      El cliente puede revocar el consentimiento que, en su caso,
                      nos haya otorgado para el tratamiento de sus datos personales.
                      Sin embargo, es importante que tenga en cuenta que no en todos
                      los casos podremos atender su solicitud o concluir el uso de
                      forma inmediata, ya que es posible que por alguna obligación
                      legal requiramos seguir tratando sus datos personales.
                      Asimismo, usted deberá considerar que para ciertos fines,
                      la revocación de su consentimiento implicará que no le podamos
                      seguir prestando el servicio que nos solicitó, o la conclusión
                      de su relación con nosotros.
                    </p>
                    <p>
                      Para revocar su consentimiento deberá presentar su solicitud
                      en el siguiente correo electrónico <i>soporte@tarjetascuenca.com</i>.
                      La cual deberá contener los requisitos siguientes (i) nombre
                      del titular de la información, su domicilio y correo electrónico
                      u otro medio para comunicar la respuesta; (ii) documentos que
                      acrediten su identidad o bien la representación legal
                      correspondiente; (iii) descripción clara y precisa de los datos
                      personales respecto de los cuales se ejerza el derecho de
                      revocación correspondiente; y (iv) cualquier otro elemento que
                      facilite la localización de los datos personales.
                    </p>
                  </Box>
                </Box>
                <Box className={classes.container}>
                  <Box>
                    <p className={classes.subSectionIndex}>
                      Contacto
                    </p>
                  </Box>

                  <Box className={classes.subSectionText}>
                    <p>
                      Para mayor información sobre el ejercicio de cualquier
                      derecho establecido en el presente Aviso de Privacidad,
                      el cliente podrá contactar al encargado del Departamento
                      de Datos Personales de Tarjetas Cuenca por correo electrónico
                      según se describe a continuación:
                    </p>
                    <p>
                      Correo electrónico: <i>soporte@tarjetascuenca.com</i>
                    </p>
                    <p>
                      Teléfono de contacto: 5513285364
                    </p>
                  </Box>
                </Box>
                <Box className={classes.container}>
                  <Box>
                    <p className={classes.subSectionIndex}>
                      Limitación del uso o divulgación de su información personal
                    </p>
                  </Box>

                  <Box className={classes.subSectionText}>
                    <p>
                      Con objeto de que usted pueda limitar el uso y divulgación
                      de su información personal, le ofrecemos los siguientes medios:
                    </p>
                    <p>
                      Su inscripción en el Registro Público de Usuarios (REUS),
                      un padrón brindado por la Comisión Nacional para la Protección
                      y Defensa de los Usuarios de Servicios Financieros (CONDUSEF),
                      que contiene información personal de los usuarios del sistema
                      financiero mexicano que no desean ser molestados con publicidad
                      y/o promociones por parte de las instituciones financieras en
                      sus prácticas de mercadotecnia.
                    </p>
                    <p>
                      Su inscripción en el Registro Público para Evitar Publicidad
                      (REPEP), un padrón brindado por la Procuraduría Federal del
                      Consumidor, que contiene un listado de consumidores que no desean
                      ser molestados con llamadas o mensajes de publicidad y/o
                      promociones por parte de empresas distintas a las instituciones
                      financieras en sus prácticas de mercadotecnia.
                    </p>
                    <p>
                      Lo señalado en los dos párrafos precedentes, no es aplicable
                      en tratándose de llamadas que pueda recibir con motivo de
                      servicios de cobranza, fines políticos, caridad, beneficencia
                      o encuestas telefónicas.
                    </p>
                    <p>
                      Para mayor información sobre estos registros, usted puede
                      consultar el portal de Internet de la CONDUSEF o de la PROFECO,
                      o bien ponerse en contacto directo con estas, según corresponda.
                    </p>
                  </Box>
                </Box>
                <Box className={classes.container}>
                  <Box>
                    <p className={classes.subSectionIndex}>
                      El uso de tecnologías de rastreo en nuestro portal de Internet
                    </p>
                  </Box>

                  <Box className={classes.subSectionText}>
                    <p>
                      Nuestro sitio web <i>www.tarjetascuenca.com</i> automáticamente
                      recopila información utilizando cookies. Cookie es un pequeño
                      archivo con información enviada por un sitio web y almacenada
                      en el navegador del cliente en su disco duro y nos ayuda a
                      mejorar nuestros Servicios y su experiencia sobre sus preferencias.
                    </p>
                    <p>
                      Si el cliente así lo decide, puede configurar su navegador
                      web para que borre o no acepte cookies. Por favor tome en
                      cuenta que, si usted decide borrar o no aceptar cookies,
                      esto podría afectar la disponibilidad y funcionalidad de
                      nuestros Servicios.
                      Tarjetas Cuenca podría también recolectar información usando
                      web beacons (también conocidos como “gofs”, “pixel tags” y
                      “pixeles de seguimiento”). Web beacons son imágenes electrónicas
                      que pueden ser usadas por nuestros Servicios o correos
                      electrónicos y ayudarnos a generar cookies, contar las visitas,
                      comprender el uso y la efectividad de una campaña y determinar
                      si un mensaje ha sido abierto y actuar en consecuencia.
                    </p>
                  </Box>
                </Box>
                <Box className={classes.container}>
                  <Box>
                    <p className={classes.subSectionIndex}>
                      Modificaciones al Aviso de Privacidad
                    </p>
                  </Box>

                  <Box className={classes.subSectionText}>
                    <p>
                      El presente Aviso de Privacidad puede sufrir modificaciones,
                      cambios o actualizaciones derivadas de nuevos requerimientos
                      legales; de nuestras propias necesidades por los productos o
                      servicios que ofrecemos; de nuestras prácticas de privacidad;
                      de cambios en nuestro modelo de negocio, o por otras causas.
                    </p>
                    <p>
                      Nos comprometemos a mantenerlo informado sobre los cambios
                      que pueda sufrir el presente aviso de privacidad, a través
                      de nuestro sitio web <i>www.tarjetascuenca.com</i> y por medio de
                      nuestra aplicación.
                    </p>
                    <p>
                      Todas las modificaciones al presente Aviso de Privacidad
                      serán publicadas en nuestro sitio web <i>www.tarjetascuenca.com</i>
                      por lo que el cliente tiene la obligación de revisar las políticas
                      con frecuencia a fin de enterarse de las modificaciones.
                      La entrada en vigor de los cambios que sufra el presente
                      Aviso de Privacidad será de 10 días hábiles posteriores a
                      su publicación, dentro de los 5 días hábiles de su publicación
                      el cliente deberá manifestar si no está conforme con las mismas,
                      de ser así se suspenderá la prestación de Servicios. Vencido este
                      plazo, se considerará que el cliente acepta las modificaciones al
                      Aviso de Privacidad.
                    </p>
                  </Box>
                </Box>
                <Box className={classes.container}>
                  <Box>
                    <p className={classes.subSectionIndex}>
                      Aceptación
                    </p>
                  </Box>

                  <Box className={classes.subSectionText}>
                    <p>
                      Este Aviso de Privacidad está sujeto a los términos y
                      condiciones del sitio web <i>www.tarjetascuenca.com</i> lo cual
                      constituye un acuerdo legal entre el cliente y Tarjetas
                      Cuenca. El uso de los Servicios de Tarjetas Cuenca constituye
                      la manifestación tácita de la voluntad de estar de acuerdo con
                      el presente Aviso de Privacidad, sin excluir que exista una
                      forma expresa adicional de manifestar la voluntad.
                    </p>
                  </Box>
                </Box>
                <Box className={classes.container}>
                  <Box>
                    <p className={classes.subSectionIndex}>
                      Autoridad
                    </p>
                  </Box>

                  <Box className={classes.subSectionText}>
                    <p>
                      La autoridad competente en materia de protección de datos
                      personales en México es el Instituto Nacional de Transparencia,
                      Acceso a la Información y Protección de Datos Personales (INAI).
                      En caso de que el cliente considere que se están vulnerando sus
                      derechos puede acudir con el INAI para obtener mayor información
                      y ayuda al respecto.
                    </p>
                  </Box>
                </Box>

            </Grid>
        </Grid>
    )
}

export default withStyles(styles)(PrivacyPolicy);
