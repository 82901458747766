// React imports
import React from 'react';

// Theme and layout
import SEO from 'components/seo';
import WithTheme from 'components/WithTheme';
import Layout from 'views/Layout';

// Page views
import PrivacyPolicy from  'views/PrivacyPolicy';

const AvisoPage = () => (
    <WithTheme>
        <Layout>

        <SEO title='Aviso de Privacidad' />
        
        <PrivacyPolicy />

        </Layout>
    </WithTheme>
);

export default AvisoPage;
